<template>
  <b-card title="Web Pages">
    <b-tabs>
      <b-tab 
        :active="currentRouteName=='privacy-policy'"
        @click="$router.push('/privacy-policy')"
      >
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>Privacy Policy </span>
        </template>

      </b-tab>
      <b-tab
        :active="currentRouteName=='quality-policy'"
        @click="$router.push('/quality-policy')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Quality Policy</span>
        </template>

        <!-- <CopyProtection></CopyProtection> -->
      </b-tab>

      <!-- <b-tab
        :active="currentRouteName=='sitemap'"
        @click="$router.push('/sitemap')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Site Map  </span>
        </template>

      </b-tab> -->
      <b-tab
        :active="currentRouteName=='dynamic-sitemap'"
        @click="$router.push('/dynamic-sitemap')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Dynamic Site Map  </span>
        </template>

        <!-- <LinkRedirication></LinkRedirication> -->
      </b-tab>
      <b-tab
        :active="currentRouteName=='BecomeInstructor'"
        @click="$router.push('/BecomeInstructor')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Become Instructor</span>
        </template>

        <!-- <LinkRediractionArabic></LinkRediractionArabic> -->
      </b-tab>
      <b-tab
        :active="currentRouteName=='AccerditiosPage'"
        @click="$router.push('/AccerditiosPage')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Accreditations Page</span>
        </template>

        <!-- <LinkRediractionArabic></LinkRediractionArabic> -->
      </b-tab>
      <b-tab
        :active="currentRouteName=='footer'"
        @click="$router.push('/footer')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span> Footer</span>
        </template>

        <!-- <CoursesDuration></CoursesDuration> -->
      </b-tab>
      <b-tab
        :active="currentRouteName=='about_us'"
        @click="$router.push('/AboutUs')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span> AboutUs</span>
        </template>

        <!-- <AnalyticCodes></AnalyticCodes> -->
      </b-tab>
      
      <b-tab
        :active="currentRouteName=='dynamic-pages-list'"
        @click="$router.push('/dynamic-pages-list')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Dynamic Pages  </span>
        </template>

        <!-- <LinkRedirication></LinkRedirication> -->
      </b-tab>
      <b-tab
        :active="currentRouteName=='ConsultingServices'"
        @click="$router.push('/ConsultingServices')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>ConsultingServices</span>
        </template>

        <!-- <ZOHOIntegration></ZOHOIntegration> -->
      </b-tab>
      <b-tab
        :active="currentRouteName=='terms'"
        @click="$router.push('/Terms')"
      >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Terms</span>
        </template>

        <!-- <HomePageSections></HomePageSections> -->
      </b-tab>

    </b-tabs>

    <router-view v-slot="{ Component, route }">
      <indexUser v-if="route.name === 'setting-index' || route.name === 'index-user' " />
      <router-view v-else />
    </router-view>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,

  },
  data() {
    return {

    }
  },

  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },

}
</script>
